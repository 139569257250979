<template>
  <div class="mobile-container">
    <div class="bg-item img-cover">
      <img src="../../assets/images/gg-bg.png" />
      <div class="back-item img-cover" @click="$router.go(-1)"><img src="../../assets/images/back-icon-black.png" /></div>
      <div class="page-title">系统公告</div>
    </div>
    <div class="page-data-list">
      <div v-for="(item, index) in ggList" :key="index" class="item">
        <div class="title">{{ item.Title }}</div>
        <div class="detail" v-html="item.Content"></div>
        <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
 
export default {
  name: "Gg",
  data() {
    return {
      ggList: []
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
    this.getGgList()
  },
  methods: {
    async getGgList() {
      var res = await this.$API.announcedList.post();
      this.ggList = res.info || []
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.bg-item {
  position: relative;
  width: 100%;
  height: 6rem;
  .back-item {
    cursor: pointer;
    position: absolute;
    left: .14rem;
    top: .14rem;
    width: .6rem;
    height: .6rem;
  }
  .page-title {
    position: absolute;
    left: .4rem;
    top: 2.14rem;
    line-height: .7rem;
    font-size: .48rem;
    font-weight: bold;
    color: #242941;
  }
}
.page-data-list {
  position: relative;
  flex: 1;
  margin-top: -2.66rem;
  overflow-y: auto;
  padding: 0 .32rem;
  .item {
    margin-bottom: .24rem;
    padding: .32rem .4rem;
    background-color: #fff;
    box-shadow: 0 .12rem .24rem .02rem rgba(19, 153, 255, .12);
    border-radius: .24rem;
    .title {
      line-height: .4rem;
      font-size: .28rem;
      font-weight: bold;
      color: #242941;
    }
    .detail, .time {
      line-height: .34rem;
      color: #b3b3b3;
      font-size: .24rem;
      margin-top: .28rem;
    }
  }
}
</style>
